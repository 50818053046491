import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);


const router = new Router({
  mode: "history", //路由默认为hash模式，Url中带有#，设置为history模式就可以去除#
  scrollBehavior(to, from, savePosition) {
    // return 期望滚动到哪个的位置
    // console.log(to.hash)
    // 有锚点，则跳转到指定id处,否则跳回顶部
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    else {
      return {
        y: 0
      }
    }
  },
  routes: [
    {
      path: "/",
      redirect: "/Home"
    },
    {
      path: "/App",
      component: {render: (e) => e("router-view")}, // 此层路由不指定组件
      // meta: { title: "" },
      children:[
        {
          path: "UiSecret",
          
          component: () => import("@/components/Views/DownLoad/uisecret"),
          meta: { title: "智优艾下载页" }
        },
        {
          path: "Uienergize",
          component: () => import("@/components/Views/DownLoad/uisecret"),
          meta: { title: "智优艾下载页" }
        },
      ]
    },
    {
      path: "/Home",
      component: () => import("@/components/Layout/Home"),
      meta: { title: "布局文件" },
      children: [
        {
          path: "/",
          component: () => import("@/components/Views/Home/Index"),
          meta: { title: "首页" }
        },
        {
          path: "/Founder",
          component: () => import("@/components/Views/BrandStory/Founder"),
          meta: { title: "品牌故事" }
        },

        {
          path: "/UIMall",
          component: () => import("@/components/Views/UIMall/UIMall"),
          meta: { title: "优艾城" }
        },
        {
          path: "/FindMore",
          component: () => import("@/components/Views/FindMore/FindMore"),
          meta: { title: "发现更多" }
        },
        {
          path: "/System",
          component: () => import("@/components/Views/System/System"),
          meta: { title: "创诚系统" }
        },
        {
          path: "/SystemDetail",
          component: () => import("@/components/Views/System/SystemDetail"),
          meta: { title: "系统活动详情" }
        },
        {
          path: "/Products",
          component: () => import("@/components/Views/Products/Products"),
          meta: { title: "产品系列" }
        },
        {
          path: "/ProductDetail",
          component: () => import("@/components/Views/Products/ProductDetail"),
          meta: { title: "产品详情" }
        },
        {
          path: "/BrandInfo",
          component: () => import("@/components/Views/BrandInfo/BrandInfo"),
          meta: { title: "品牌资讯" }
        },
        {
          path: "/BrandInfoDetail",
          component: () => import("@/components/Views/BrandInfo/BrandInfoDetail"),
          meta: { title: "资讯详情" }
        },


      ]
    }
  ]
});

export default router;