import Vue from "vue";
import Vuex from "vuex";


Vue.use(Vuex);


const state = {
    brandInfoType:JSON.parse(sessionStorage.getItem("brandInfoType")) || "",
    menuIndex:sessionStorage.getItem("menuIndex") || 0

  
  };
  const mutations = {
    handleBrandInfoType(state, info) {
      state.brandInfoType = info;
      sessionStorage.setItem("brandInfoType", JSON.stringify(info));
    },
    handleMenuIndex(state, info) {
        state.menuIndex = info;
        sessionStorage.setItem("menuIndex", info);
      },
  };
  const getters = {
    brandInfoType: state => state.brandInfoType,
    menuIndex: state => state.menuIndex,
  
  };
  const actions = {};
  const modules = {};
  
  const store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules
  });
  
  export default store;