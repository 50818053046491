import Vue from 'vue'
import App from './App.vue'
import router from './router';
import Axios from 'axios'
import store from "./store/index";



import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.prototype.$store = store; 

// 不需要每次都 import一下 axios了，直接使用 $axios 即可
Vue.prototype.$axios = Axios

// 使每次请求都会带一个 /api 前缀 
Axios.defaults.baseURL = '/api'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
